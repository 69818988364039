<template>
  <div v-loading="loading" class="order-list-container">
    <el-scrollbar ref="elScroll" class="scrollBox" style="height: 600px">
      <ul v-for="(item, i) in tableData" :key="i" class="order-list">
        <li class="list-item">
          <div class="flex item-header" :style="getCurrentBgC(item.state)">
            <div class="item-header-l">
              <span class="head-text time">创建时间：{{ item.createTime }}</span>
              <span class="head-text">子服务单号：{{ item.serviceItemNo }}</span>
              <span v-if="type != 'detailSubOrder'" class="head-text">
                <span class="number-text">关联订单号：</span>
                <el-link :underline="false" type="primary" @click="goServiceDetail(item)">{{
                  item.serviceNo
                }}</el-link>
              </span>
            </div>
            <div v-if="item.state == 7" class="reason">
              <el-tooltip
                class="item"
                effect="dark"
                :content="'拒单理由：' + item.reason"
                placement="top"
              >
                <div>拒单理由：{{ item.reason || '--' }}</div>
              </el-tooltip>
            </div>
            <div class="item-header-r">
              {{ item.stateName }}
            </div>
          </div>
          <div class="flex item-content">
            <div class="item-common left">
              <div class="item-lable">服务信息：</div>
              <div
                v-if="
                  item.baseProductType == 1 ||
                  item.baseProductType == 2 ||
                  item.baseProductType == 3 ||
                  item.baseProductType == 4 ||
                  item.baseProductType == 15
                "
                class="item-text"
              >
                服务时间：{{ (item.serviceDto || {}).appointmentShootDatetime || '--' }}
              </div>
              <div v-if="item.baseProductType == 8 && !!item.entityInfo" class="item-text">
                服务内容：{{ JSON.parse(item.entityInfo).productName }} |
                {{ item.baseProductName }} | {{ getBaseProductTypeName(item.baseProductType) }} | 1
                {{ JSON.parse(item.entityInfo).priceUnit }} | 1 {{ item.priceUnit }}
              </div>
              <div v-else class="item-text">
                服务内容：{{ item.baseProductName }} |
                {{ getBaseProductTypeName(item.baseProductType) }} | {{ item.number
                }}{{ item.priceUnit }}
              </div>
              <div
                v-if="
                  item.baseProductType == 1 ||
                  item.baseProductType == 2 ||
                  item.baseProductType == 3 ||
                  item.baseProductType == 4 ||
                  item.baseProductType == 15
                "
                class="item-text"
              >
                服务地址：{{ (item.serviceDto || {}).appointmentStroeAddress || '--' }}
              </div>
              <div class="item-text">
                预约信息：{{ item.contactPersonName || '--' }} {{ item.contactPersonPhone || '--' }}
              </div>
            </div>
            <div class="item-common">
              <div class="item-lable">派单时间：</div>
              <div class="item-text">{{ item.dispatchTime || '--' }}</div>
            </div>
            <div class="item-common">
              <div class="item-lable">接单时间：</div>
              <div class="item-text">{{ item.orderTakeTime || '--' }}</div>
            </div>
            <div class="item-common">
              <div class="item-lable">指派服务人员：</div>
              <div class="item-text">{{ item.serviceUserName || '--' }}</div>
              <div class="item-text">{{ item.assignServicePhone || '--' }}</div>
            </div>
            <div class="item-common">
              <div class="item-lable">完成时间：</div>
              <div class="item-text">{{ item.finishTime || '--' }}</div>
              <div v-if="item.serviceItemScore">
                <div class="item-lable">服务评价：</div>
                <div class="item-text">
                  <el-rate v-model="item.serviceItemScore" disabled></el-rate>
                </div>
              </div>
            </div>
            <div class="item-common item-content-butoon">
              <el-button class="button-detail" type="primary" @click="detail(item)"
                >查看详情</el-button
              >
              <Btns :query-type="queryType" :type="type" :item="item" @refresh="getTableList" />
            </div>
          </div>
        </li>
      </ul>
      <el-empty v-if="!tableData || tableData.length == 0" :image-size="200"></el-empty>
    </el-scrollbar>
  </div>
</template>

<script>
import Btns from './btns.vue'
import { getBaseProductTypeName } from './common'
export default {
  components: { Btns },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
    queryType: {
      type: [Number, String],
      default: 1,
    },

    baseServiceType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {}
  },
  computed: {
    getBaseProductTypeName,
    getCurrentBgC() {
      return (status) => {
        switch (status) {
          case 0:
            return { backgroundColor: '#ec808d63' }
          case 7:
            return { backgroundColor: '#ec808d63' }
          case 2:
            return { backgroundColor: '#dabb94' }
          case 1:
            return { backgroundColor: '#f59a2338' }
          case 3:
            return { backgroundColor: '#b5d8ff' }
          case 6:
            return { backgroundColor: '#b5d8ff' }
          case 4:
            return { backgroundColor: '#cacaca' }
          case 8:
            return { backgroundColor: '#cacaca' }
          case 5:
            return { backgroundColor: '#e0e0e0' }
          case 9:
            return { backgroundColor: '#e0e0e0' }
          default:
            return { backgroundColor: '#f59a2338' }
        }
      }
    },
  },
  watch: {
    loading(val) {
      if (val)
        this.$nextTick(() => {
          this.$refs.elScroll.$refs.wrap.scrollTop = 0
        })
    },
  },
  methods: {
    getTableList() {
      this.$parent.getTableData()
      if (this.type === 'detailSubOrder') {
        this.$emit('refresh')
      }
    },
    goServiceDetail(item) {
      this.$router.push({ path: '/orderDetail', query: { serviceId: item.serviceId } })
    },
    detail(item) {
      const routeUrl = this.$router.resolve({
        name: 'subOrderDetail',
        // path: '/serviceManagement/subOrderDetail',
        query: { serviceItemId: item.serviceItemId, type: item.baseProductType },
      })
      window.open(routeUrl.href, '_blank')
    },
  },
}
</script>

<style lang="less" scoped>
.item-header {
  flex-wrap: nowrap;
  // line-height: 22px;
  align-items: center;
}

.item-header-l {
  flex: 1;
  white-space: nowrap;
}

.item-header-r {
  color: #333;
  font-weight: bold;
  font-size: 18px;
}

.reason {
  flex: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  .el-tooltip {
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
</style>
