<template>
  <div class="header">
    <el-form :inline="true" :model="form" class="demo-form-inline">
      <el-form-item v-if="queryType != 2" label="服务状态：">
        <el-select v-model="form.state" style="width: 150px" placeholder="请选择">
          <el-option v-for="(n, i) in serviceStatusList" :key="i" :label="n.name" :value="n.val"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="指派服务人员手机号：">
        <el-input v-model="form.assignServicePhone" style="width: 150px" placeholder="请输入手机号"></el-input>
      </el-form-item>
      <el-form-item label="关联订单号：">
        <el-input v-model="form.serviceNo" style="width: 220px" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="预约时间:">
        <el-date-picker
          v-model="appointmentDates"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
          :default-time="['00:00:00', '23:59:59']"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          :picker-options="subscribePickerOptions"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="创建时间：">
        <el-date-picker
          v-model="date1"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
          :default-time="['00:00:00', '23:59:59']"
          range-separator="至"
          style="width: 350px"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="派单时间：">
        <el-date-picker
          v-model="date"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
          :default-time="['00:00:00', '23:59:59']"
          range-separator="至"
          style="width: 350px"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">查询</el-button>
        <el-button icon="el-icon-refresh-left" @click="reset">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import moment from 'moment'
// import { getPostPage } from '@/api/user';
let vm = null
export default {
  name: 'PageHeader',
  components: {},
  props: {
    serviceStatusList: {
      type: Array,
      default: () => [],
    },
    queryType: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      form: {
        state: '',
      },
      date1: null,
      date: null,
      subscribePickerOptions: {
        shortcuts: [
          {
            text: '后一天',
            onClick(picker) {
              let start = ''
              let end = ''
              let laterDate = null
              if (vm.appointmentDates[1]) {
                laterDate = new Date(new Date(vm.appointmentDates[1]).getTime() + 24 * 60 * 60 * 1000)
              } else {
                laterDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
              }

              const year = laterDate.getUTCFullYear()
              const month = laterDate.getMonth() >= 9 ? laterDate.getMonth() + 1 : `0${laterDate.getMonth() + 1}`
              const day = laterDate.getDate() >= 10 ? laterDate.getDate() : `0${laterDate.getDate()}`

              start = `${year}-${month}-${day} 00:00:00`
              end = `${year}-${month}-${day} 23:59:59`

              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            },
          },
        ],
      },
      appointmentDates: [],
    }
  },
  created() {
    vm = this
  },
  mounted() {
    const state = this.$route.query.state
    if (state) {
      this.form.state = Number(state)
    }
  },
  methods: {
    onSubmit() {
      const data = { ...this.form }
      if (this.date) {
        data.beginDispatchTime = moment(this.date[0]).format('YYYY-MM-DD HH:mm:ss')
        data.endDispatchTime = moment(this.date[1]).format('YYYY-MM-DD HH:mm:ss')
      }
      if (this.appointmentDates && this.appointmentDates.length) {
        data.appointmentShootStartTime = this.appointmentDates[0]
        data.appointmentShootEndTime = this.appointmentDates[1]
      }
      if (this.date1) {
        data.beginOrderTakeTime = moment(this.date1[0]).format('YYYY-MM-DD HH:mm:ss')
        data.endOrderTakeTime = moment(this.date1[1]).format('YYYY-MM-DD HH:mm:ss')
      }

      this.$emit('search', data)
    },
    reset() {
      this.form = {}
      this.date1 = null
      this.date = null
      this.$emit('search', {})
    },

    checkFold() {
      this.fold = !this.fold
    },
  },
}
</script>
<style lang="less" scoped></style>
