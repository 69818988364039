var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"order-list-container"},[_c('el-scrollbar',{ref:"elScroll",staticClass:"scrollBox",staticStyle:{"height":"600px"}},[_vm._l((_vm.tableData),function(item,i){return _c('ul',{key:i,staticClass:"order-list"},[_c('li',{staticClass:"list-item"},[_c('div',{staticClass:"flex item-header",style:(_vm.getCurrentBgC(item.state))},[_c('div',{staticClass:"item-header-l"},[_c('span',{staticClass:"head-text time"},[_vm._v("创建时间："+_vm._s(item.createTime))]),_c('span',{staticClass:"head-text"},[_vm._v("子服务单号："+_vm._s(item.serviceItemNo))]),(_vm.type != 'detailSubOrder')?_c('span',{staticClass:"head-text"},[_c('span',{staticClass:"number-text"},[_vm._v("关联订单号：")]),_c('el-link',{attrs:{"underline":false,"type":"primary"},on:{"click":function($event){return _vm.goServiceDetail(item)}}},[_vm._v(_vm._s(item.serviceNo))])],1):_vm._e()]),(item.state == 7)?_c('div',{staticClass:"reason"},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":'拒单理由：' + item.reason,"placement":"top"}},[_c('div',[_vm._v("拒单理由："+_vm._s(item.reason || '--'))])])],1):_vm._e(),_c('div',{staticClass:"item-header-r"},[_vm._v(" "+_vm._s(item.stateName)+" ")])]),_c('div',{staticClass:"flex item-content"},[_c('div',{staticClass:"item-common left"},[_c('div',{staticClass:"item-lable"},[_vm._v("服务信息：")]),(
                item.baseProductType == 1 ||
                item.baseProductType == 2 ||
                item.baseProductType == 3 ||
                item.baseProductType == 4 ||
                item.baseProductType == 15
              )?_c('div',{staticClass:"item-text"},[_vm._v(" 服务时间："+_vm._s((item.serviceDto || {}).appointmentShootDatetime || '--')+" ")]):_vm._e(),(item.baseProductType == 8 && !!item.entityInfo)?_c('div',{staticClass:"item-text"},[_vm._v(" 服务内容："+_vm._s(JSON.parse(item.entityInfo).productName)+" | "+_vm._s(item.baseProductName)+" | "+_vm._s(_vm.getBaseProductTypeName(item.baseProductType))+" | 1 "+_vm._s(JSON.parse(item.entityInfo).priceUnit)+" | 1 "+_vm._s(item.priceUnit)+" ")]):_c('div',{staticClass:"item-text"},[_vm._v(" 服务内容："+_vm._s(item.baseProductName)+" | "+_vm._s(_vm.getBaseProductTypeName(item.baseProductType))+" | "+_vm._s(item.number)+_vm._s(item.priceUnit)+" ")]),(
                item.baseProductType == 1 ||
                item.baseProductType == 2 ||
                item.baseProductType == 3 ||
                item.baseProductType == 4 ||
                item.baseProductType == 15
              )?_c('div',{staticClass:"item-text"},[_vm._v(" 服务地址："+_vm._s((item.serviceDto || {}).appointmentStroeAddress || '--')+" ")]):_vm._e(),_c('div',{staticClass:"item-text"},[_vm._v(" 预约信息："+_vm._s(item.contactPersonName || '--')+" "+_vm._s(item.contactPersonPhone || '--')+" ")])]),_c('div',{staticClass:"item-common"},[_c('div',{staticClass:"item-lable"},[_vm._v("派单时间：")]),_c('div',{staticClass:"item-text"},[_vm._v(_vm._s(item.dispatchTime || '--'))])]),_c('div',{staticClass:"item-common"},[_c('div',{staticClass:"item-lable"},[_vm._v("接单时间：")]),_c('div',{staticClass:"item-text"},[_vm._v(_vm._s(item.orderTakeTime || '--'))])]),_c('div',{staticClass:"item-common"},[_c('div',{staticClass:"item-lable"},[_vm._v("指派服务人员：")]),_c('div',{staticClass:"item-text"},[_vm._v(_vm._s(item.serviceUserName || '--'))]),_c('div',{staticClass:"item-text"},[_vm._v(_vm._s(item.assignServicePhone || '--'))])]),_c('div',{staticClass:"item-common"},[_c('div',{staticClass:"item-lable"},[_vm._v("完成时间：")]),_c('div',{staticClass:"item-text"},[_vm._v(_vm._s(item.finishTime || '--'))]),(item.serviceItemScore)?_c('div',[_c('div',{staticClass:"item-lable"},[_vm._v("服务评价：")]),_c('div',{staticClass:"item-text"},[_c('el-rate',{attrs:{"disabled":""},model:{value:(item.serviceItemScore),callback:function ($$v) {_vm.$set(item, "serviceItemScore", $$v)},expression:"item.serviceItemScore"}})],1)]):_vm._e()]),_c('div',{staticClass:"item-common item-content-butoon"},[_c('el-button',{staticClass:"button-detail",attrs:{"type":"primary"},on:{"click":function($event){return _vm.detail(item)}}},[_vm._v("查看详情")]),_c('Btns',{attrs:{"query-type":_vm.queryType,"type":_vm.type,"item":item},on:{"refresh":_vm.getTableList}})],1)])])])}),(!_vm.tableData || _vm.tableData.length == 0)?_c('el-empty',{attrs:{"image-size":200}}):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }