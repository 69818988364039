<template>
  <div class="wrap">
    <div v-show="!dialogVisible">
      <ul class="flex order-tab">
        <div v-for="(item, i) in tabList" :key="i" @click="handleTab(item, i)">
          <li v-if="item.show" class="tab-list" :class="i === tabIndex ? 'tab-list-checked' : ''">
            <span>{{ item.name }}</span>
            <span v-if="i == 1">({{ statusCount > 99 ? '99+' : statusCount }})</span>
          </li>
        </div>
      </ul>
      <page-header
        :query-type="form.data.queryType"
        :service-status-list="serviceStatusList"
        @search="search"
      />
      <div class="table">
        <Table
          :query-type="form.data.queryType"
          :base-service-type="baseServiceType"
          type="list"
          :table-data="tableData"
          :loading="loading"
        />
        <Pages
          :current-page="form.pageNumber"
          :total="total"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import pageHeader from './components/header.vue'
import Table from './components/table'
import {
  storeGetList,
  //  findListByRoleIds
} from '@/api/order'

export default {
  components: {
    pageHeader,
    Table,
  },
  props: {
    type: {
      type: String,
      default: '',
    },
    tabList: {
      type: Array,
      default: () => [],
    },
    baseServiceType: {
      type: String,
      default: '',
    },
    serviceStatusList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialogVisible: false,
      tableData: [],
      total: 0,
      form: {
        pageSize: 10,
        pageNumber: 1,
        data: {
          queryType: 1,
        },
      },
      loading: false,
      tabIndex: 0,
      row: {},
      statusCount: 0,
    }
  },
  computed: {},
  watch: {
    tabList: {
      handler(val) {
        console.log(val)
      },
      // 这里是关键，代表递归监听 demo 的变化
      deep: true,
    },
  },
  async mounted() {
    // findListByRoleIds({ data: { roleIds: ['192'] } }).then((res) => {
    //   window.sessionStorage.setItem('btnPermissions', JSON.stringify(res.data));
    // });
    this.tabIndex = this.tabList.findIndex((e) => e.show)
    this.form.data.queryType = this.tabList.find((e) => e.show).status
    const type = this.$route.query.queryType
    const state = this.$route.query.state
    if (type) {
      if (!this.tabList.find((e) => e.status === 2 && e.show)) this.$router.push('/404')
      this.form.data.queryType = type
      this.tabIndex = 1
    }
    if (state) {
      if (!this.tabList.find((e) => e.status === 1 && e.show)) this.$router.push('/404')
      this.form.data.state = state
    }
    await this.getTableData()
    if (this.tabList.find((e) => e.status === 2 && e.show)) await this.getNum()
  },
  methods: {
    async getNum() {
      await storeGetList({
        pageSize: 1,
        pageNumber: 1,
        data: {
          queryType: 2,
          baseServiceType: this.baseServiceType,
        },
      }).then((res) => {
        this.statusCount = Number(res.data.total)
      })
    },
    async handleTab(item, index) {
      this.tabIndex = index
      this.form.pageNumber = 1
      this.form.data.queryType = item.status
      await this.getTableData()
      if (index === 1) await this.getNum()
    },
    async getTableData() {
      this.loading = true
      this.tableData = []
      this.form.data.baseServiceType = this.baseServiceType
      await storeGetList(this.form).then((res) => {
        this.total = Number(res.data.total)
        res.data.content.forEach((e) => {
          if (e.serviceItemScore) {
            e.serviceItemScore = Number(e.serviceItemScore)
          }
        })
        this.tableData = res.data.content
        this.$forceUpdate()
        this.loading = false
      })
    },
    search(data) {
      this.form.pageNumber = 1
      this.form.data = { ...data, queryType: this.form.data.queryType }
      // if (JSON.stringify(data) == '{}') this.tabIndex = 0;
      this.getTableData()
    },

    handleSizeChange(val) {
      this.form.pageSize = val
      this.form.pageNumber = 1
      this.getTableData()
    },
    handleCurrentChange(val) {
      this.form.pageNumber = val
      this.getTableData()
    },
  },
}
</script>
<style lang="less" scoped>
.wrap {
  padding-top: 4px;
}
.moreAction {
  font-size: 14px;
  color: #666;
}
</style>
